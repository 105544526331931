import dayjs from "dayjs";
import arraySupport from "dayjs/plugin/arraySupport";
import utc from "dayjs/plugin/utc";

export type DateTimeViewAttrs = {
    dateTime?: any;
}

function DateTimeView({dateTime}: DateTimeViewAttrs) {
    // const data = [2024, 12, 3, 12, 28, 16, 614000000];
    const data = dateTime;

    dayjs.extend(arraySupport);
    dayjs.extend(utc);

    if (!dateTime) {
        return (
            <>
                -
            </>
        )
    }

    let date = dayjs.utc(new Date(
        data[0],
        data[1] - 1,
        data[2],
        data[3],
        data[4],
        data[5],
        data[6] / 1000000
    ));

    date = date.add(2, 'hour');

    return (
        <>
            { date.format('YYYY-MM-DD HH:mm') }
        </>
    );
}

export default DateTimeView;
